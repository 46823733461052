<template>
  <section
    class="relative md:py-24 py-16"
    id="news"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Mitra Kami</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Berikut daftar mitra dari Mall Pelayanan Publik Kota Cilegon.</p>
      </div><!--end grid-->
      <!-- Karosel -->
      <div class="relative group overflow-hidden my-10">
        <!-- Carousel Wrapper -->
        <div
          class="flex transition-transform duration-500 ease-in-out py-2 "
          :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
        >
          <!-- Carousel Slide -->
          <div
            v-for="(slide, index) in chunkRecords"
            :key="index"
            class="flex-shrink-0 w-full grid grid-cols-5 gap-5"
          >
            <div
              v-for="item in slide"
              :key="item.id"
              class="flex justify-center items-center w-80 h-auto md:w-[100px] md:h-[150px]  overflow-hidden  rounded-md "
            >
              <div class="flex justify-center items-center  h-auto md:max-w-[100px] md:max-h-[150px] ">
                <img
                  :src="item.logo"
                  alt=""
                  srcset=""
                  class="w-auto h-auto object-fill hover:cursor-pointer hover:scale-110 transition-all duration-300"
                >

              </div>

            </div>

          </div>
        </div>

        <!-- Navigation Buttons -->
        <button
          class="absolute top-1/2 left-4 -translate-y-1/2 bg-lime-600 text-white p-2 rounded-full hover:bg-gray-700"
          @click="prevSlide"
          v-if="currentIndex > 0"
        >
          ❮
        </button>
        <button
          class="absolute top-1/2 right-4 -translate-y-1/2 bg-lime-500 text-white p-2 rounded-full hover:bg-gray-700"
          @click="nextSlide"
          v-if="currentIndex < chunkRecords.length - 1"
        >
          ❯
        </button>
      </div>

    </div><!--end container-->
  </section>
</template>
  
<script>
import feather from "feather-icons";
import axios from "axios";
import { baseurl } from "../config/setting";
export default {
  data: () => ({
    baseurl: baseurl,
    records: [],
    totalRecords: 0,
    chunkRecords: 5,
    currentIndex: 0,
  }),
  methods: {
    fetchRecords: async function () {
      try {
        const endpoint = this.baseurl + "/api/v1/tenants";
        let {
          data: { success, status, message, data },
        } = await axios.get(endpoint);

        this.records = data;
        this.totalRecords = data.length;
        this.chunkRecords = this.chunkItems();
      } catch (error) {
        console.log(error);
      }
    },

    chunkItems: function () {
      const result = [];

      for (let i = 0; i < this.totalRecords; i += 5) {
        result.push(this.records.slice(i, i + 5));
      }

      return result;
    },

    prevSlide: function () {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },

    nextSlide: function () {
      if (this.currentIndex < this.chunkRecords.length - 1) {
        this.currentIndex++;
      }
    },
  },
  mounted() {
    this.fetchRecords();
    feather.replace();
  },
};
</script>