<template>
  <section
    class="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800"
    id="antrian"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Formulir Pendaftaran Antrian</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Silahkan isi formulir dibawah ini untuk melakukan pemesana antrian pelayanan kami..!</p>
      </div><!--end grid-->

      <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
        <div class="lg:col-span-6 md:col-span-6 hidden sm:block">
          <img
            src="../assets/images/contact.svg"
            alt=""
          >
        </div>

        <div class="lg:col-span-6 md:col-span-6">
          <div class="lg:ms-5">
            <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
              <div
                class="flex items-center p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg"
                role="alert"
                v-if="antrian.success"
              >
                <svg
                  class="w-5 h-5 mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 100 20 10 10 0 000-20zM9 14h2v2H9v-2zm0-10h2v8H9V4z" />
                </svg>
                <span class="font-medium">Proses pengambilan antrian berhasil.</span>
              </div>

              <div
                class="flex items-center p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
                role="alert"
                v-if="antrian.error"
              >
                <svg
                  class="w-5 h-5 mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 100 20 10 10 0 000-20zM9 14h2v2H9v-2zm0-10h2v8H9V4z" />
                </svg>
                <span class="font-medium">Opps..., Silahkan isi data dengan benar</span>
              </div>

              <form>
                <div class="grid lg:grid-cols-12 grid-cols-1 gap-3">
                  <div class="lg:col-span-12">
                    <label
                      for="name"
                      class="font-semibold"
                    >Nama Lengkap:</label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      v-model="antrian.record.name"
                      class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0  focus:border-teal-500"
                      placeholder="Name :"
                    >
                  </div>

                  <div class="lg:col-span-12">
                    <label
                      for="email"
                      class="font-semibold"
                    >Email:</label>
                    <input
                      v-model="antrian.record.email"
                      name="email"
                      id="email"
                      type="email"
                      class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 focus:border-teal-500"
                      placeholder="Email :"
                    >
                  </div>
                  <div class="lg:col-span-12">
                    <label
                      for=""
                      class="font-semibold"
                    >No HP (Whatsapp) :</label>
                    <input
                      name="phone"
                      id="email"
                      v-model="antrian.record.phone"
                      class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0  focus:border-teal-500"
                      placeholder="No Whatsapp : 0812XXXXX"
                      max-length="13"
                    >
                  </div>
                  <div class="lg:col-span-12">
                    <label
                      for="subject"
                      class="font-semibold"
                    >Tanggal Kedatangan:</label>
                    <input
                      v-model="antrian.record.booked_at"
                      name="subject"
                      id="subject"
                      class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Subject :"
                      type="date"
                    >
                  </div>
                  <div class="lg:col-span-12">
                    <label
                      for="subject"
                      class="font-semibold"
                    >Jam Kedatangan:</label>
                    <input
                      v-model="antrian.record.time_at"
                      name="subject"
                      id="subject"
                      class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Subject :"
                      type="time"
                    >
                  </div>

                  <div class="lg:col-span-12">
                    <label for="">Pelayanan</label>
                    <select
                      name=""
                      id=""
                      v-model="antrian.record.tenant_id"
                      class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100"
                      @change="fetchTenantServices"
                    >
                      <option
                        value="00"
                        disabled
                        selected
                      >-- pilih pelayanan --</option>
                      <option
                        :value="tenant.value"
                        v-for="tenant in tenants"
                        :key="tenant.value"
                      >{{ tenant.title }}</option>
                    </select>
                  </div>
                  <div class="lg:col-span-12">
                    <label for="">Jenis Pelayanan</label>
                    <select
                      name=""
                      id=""
                      v-model="antrian.record.tenant_service_uuid"
                      class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100"
                    >
                      <option
                        value="00"
                        disabled
                        selected
                      >-- pilih jenis pelayanan --</option>
                      <option
                        v-for="item in tenantServices"
                        :key="item.value"
                        :value="item.value"
                      >{{ item.title }}</option>
                    </select>
                  </div>

                </div>
                <div class="py-5">
                  <button
                    @click="postAntrian"
                    :class="antrian.loading ? `relative flex items-center justify-center px-4 py-2 font-semibold text-white bg-teal-500 border border-transparent rounded-md shadow-sm hover:bg-teal-600 disabled:opacity-50` : `relative flex items-center justify-center px-4 py-2 font-semibold text-white bg-teal-500 border border-transparent rounded-md shadow-sm hover:bg-teal-600 `"
                    :disabled="antrian.loading"
                  >
                    <span
                      class="absolute inset-0 flex items-center pl-2 "
                      id="spinnerContainer"
                      v-if="antrian.loading"
                    >
                      <svg
                        class="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                    </span>
                    <span
                      class="ml-3"
                      v-if="antrian.loading"
                    >Processing...</span>
                    <span
                      class=""
                      v-if="!antrian.loading"
                    >Kirim</span>
                  </button>
                </div>
                <div class="py-5">
<em>Info:<br>Kami akan mengirimkan notifikasi registrasi melalui email dan whatsapp</em>
</div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div><!--end container-->
  </section>
</template>

<script>
import axios from "axios";
import { baseurl } from "../config/setting";

export default {
  data: () => ({
    basurl: baseurl,
    tenants: [],
    tenantServices: [],
    antrian: {
      record: {
        name: null,
        email: null,
        phone: null,
        booked_at: null,
        tenant_id: null,
        tenant_service_uuid: null,
      },
      success: false,
      error: false,
      loading: false,
    },
  }),
  mounted() {
    this.fetchTenants();
  },
  methods: {
    fetchTenants: async function () {
      try {
        const endpoint = this.basurl + "/api/v1/combo/tenant";
        let {
          data: { data },
        } = await axios.get(endpoint);

        this.tenants = data ? data : [];
      } catch (error) {}
    },
    fetchTenantServices: async function () {
      try {
        this.tenantServices = [];
        const endpoint =
          this.basurl +
          "/api/v1/combo/tenant-service/" +
          this.antrian.record.tenant_id;
        let {
          data: { data },
        } = await axios.get(endpoint);

        this.tenantServices = data ? data : [];
      } catch (error) {}
    },
    postAntrian: async function () {
      try {
        this.antrian.loading = true;
        const endpoint = this.basurl + "/api/v1/ambil-antrian";
        let {
          data: { success, message, data },
        } = await axios.post(endpoint, this.antrian.record);

        this.antrian.record = {
          name: null,
          email: null,
          phone: null,
          booked_at: null,
          tenant_id: null,
          tenant_service_uuid: null,
        };

        this.antrian.success = true;
        setTimeout(() => {
          this.antrian.success = false;
        }, 5000);
      } catch (error) {
        this.antrian.error = true;
        setTimeout(() => {
          this.antrian.error = false;
        }, 5000);
      } finally {
        this.antrian.loading = false;
      }
    },
  },
};
</script>
