import { createRouter, createWebHistory } from 'vue-router'
import IndexSeven from '../pages/IndexTwo'
import NewsDetail from '@/pages/NewsDetail.vue'
import Download from "@/pages/Download.vue"
import ReportDownload from "@/pages/ReportDownload.vue"

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexSeven
  },
  {
    path: '/news-detail/:id',
    name: 'news-detail',
    component: NewsDetail
  },
  {
    path: '/download',
    name: 'download',
    component: Download,
  },
  {
    path: '/download-reporting',
    name: 'download-reporting',
    component: ReportDownload,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
