<template>
  <div>
    <nav-light />
    <section
      class="relative md:h-[200px] md:py-0 py-10 items-center overflow-hidden bg-gradient-to-t to-teal-600 via-teal-600/50 from-transparent"
      id="home"
    >
      <div class="container relative">
        <div class="grid grid-cols-1 md:mt-20 mt-0 text-center">
          <h4 class="font-bold lg:leading-normal leading-normal tracking-wide text-4xl lg:text-5xl capitalize text-white mb-5"></h4>
        </div><!--end grid-->
      </div><!--end container-->
    </section>
    <div class="">
      <div class="container h-screen">
        <div class="flex justify-center">
          <h1 class="text-2xl text-gray-700 font-bold">Data Unduhan File</h1>
        </div>
        <div class="overflow-x-auto">
          <table class="min-w-full border-collapse border border-gray-200 text-sm mt-10">
            <thead>
              <tr class="bg-gray-100 text-left">
                <th class="border border-gray-200 px-4 py-2 font-medium text-gray-700 w-1">NO</th>
                <th class="border border-gray-200 px-4 py-2 font-medium text-gray-700">JUDUL</th>
                <th class="border border-gray-200 px-4 py-2 font-medium text-gray-700">BULAN</th>
                <th class="border border-gray-200 px-4 py-2 font-medium text-gray-700 w-2">UNDUH</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="hover:bg-gray-50"
                v-for="(item, index) in records"
                :key="item.id"
              >
                <td class="border border-gray-200 px-4 py-2">{{ index + 1 }}</td>
                <td class="border border-gray-200 px-4 py-2">{{ item.name }}</td>
                <td class="border border-gray-200 px-4 py-2">{{ item.kategori }}</td>
                <td class="border border-gray-200 px-4 py-2">
                  <button
                    class="text-xs bg-teal-600 py-1 px-1 rounded-sm text-white hover:bg-teal-800"
                    @click="download(item.url)"
                  >Download</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import { baseurl } from "../config/setting";
import NavLight from "@/components/NavLight.vue";
export default {
  components: {
    NavLight,
  },
  data: () => ({
    record: {},
    records: {},
    baseurl: baseurl,
  }),

  methods: {
    fetchRecords: async function () {
      try {
        const endpoint = this.baseurl + "/api/v1/documents/?itemsPerPage=1000";
        let {
          data: { success, status, message, data },
        } = await axios.get(endpoint);

        this.records = data;
      } catch (error) {
        console.log(error);
      }
    },
    download: function (url) {
      window.open(url, "__BLANK");
    },
  },
  mounted() {
    this.fetchRecords();
  },
};
</script>